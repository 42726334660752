<template>
    <div class="equipment-configuration-base">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <!-- <EquipconfigGrid class='equipment-configuration-view'></EquipconfigGrid> -->
        <router-view class="equipment-configuration-view"></router-view>
    </div>
</template>
<script>
// import EquipconfigGrid from "./equipconfig_grid.vue"

export default {
    name: "equipment_configuration",
    data() {
        return {
            itemName: '配置管理',
            breadlist: [
                {
                    path: '',
                    name: '数据管理与接入'
                }
            ],
        }
    },
    components: {
        // EquipconfigGrid
    }
}
</script>
<style scoped>
.equipment-configuration-base{
    width: 100%;
    height: 100%;
    /* padding: 0px 85px 15px 85px; */
}
.equipment-configuration-view{
    width: 100%;
    height: calc(100% - 45px);
}
</style>